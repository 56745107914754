<template>
  <div id="jumbo" class="container-fluid">
  </div>
  <div id="art" class="container">
    <h1>Colorful Series</h1>
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Redmiracle_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal13" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Red Miracle (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Green_Sea_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal14" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Green Sea (2024)</p>
      </div>
    </div>  
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Pinksprinkles_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal15" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Sprinkles (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Blue_red_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal10" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Blue Red (2024)</p>
      </div>
    </div>  
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_Water_Lilies_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal11" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Water Lilies (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_Water_Lilies_II_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal12" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Water Lilies II (2024)</p>
      </div>
    </div>  
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Beauty_of_Nature_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal5" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Beauty of Nature (2023)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Spring_Flowers_I_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal4" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Spring Flowers I (2023)</p>
      </div>
    </div>     

    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Water_lilies_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal1" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Water Lilies (2023)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Beauty_of_Nature_II_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal8" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Beauty of Nature II (2023)</p>
      </div>
    </div>   
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Joy_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal6" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Joy (2022)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Spring_Flowers_II_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal7" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Spring Flowers II (2023)</p>
      </div>
    </div>
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/gree_quadratisch.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal3" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Bald Eagle (2019)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/red_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal2" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Untitled (2016)</p>
      </div>
    </div>
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Beauty_of_Nature_III_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal9" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Beauty of Nature III (2024)</p>
      </div>
    </div>         
  </div>


  <!-- Modal 1 -->
  <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <img src="../assets/Water_lilies.jpg" class="img-fluid" alt="..." style="max-height: 70vh;">
        </div>
        <div class="modal-footer">
          Water Lilies (2023)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 2 -->
  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <img src="../assets/red_1.jpg" class="img-fluid" alt="..." style="max-height: 70vh;">
        </div>
        <div class="modal-footer">
          Untitled (2016)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 3 -->
  <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <img src="../assets/IMG_8571.jpg" class="img-fluid" alt="..." style="max-height: 70vh;">
        </div>
        <div class="modal-footer">
          Bald Eagle (2019)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 4 -->
  <div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls4" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Spring_Flowers_I.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Spring_Fliwers_I_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls4" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls4" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Spring Flowers I, Acryl on Canvas, 120 x 100 cm (2023)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 5 -->
  <div class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls1" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Beauty_of_Nature.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Beauty_of_Nature_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Beauty_of_Nature_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Beauty of Nature, Acryl on Canvas, 100 x 100 cm (2023)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 6 -->
  <div class="modal fade" id="exampleModal6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <img src="../assets/Joy.jpg" class="img-fluid" alt="..." style="max-height: 70vh;">
        </div>
        <div class="modal-footer">
          Joy, Acryl on Canvas, 120 x 100 cm (2022)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 7 -->
  <div class="modal fade" id="exampleModal7" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls7" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Spring_Flowers_II.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Spring_Flowers_II_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls7" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls7" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Spring Flowers II, Acryl on Canvas, 120 x 100 cm (2023)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 8 -->
  <div class="modal fade" id="exampleModal8" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Beauty_of_Nature_II.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Beauty_of_Nature_II_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Beauty of Nature II, Acryl on Canvas, 100 x 100 cm (2023)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 9 -->
  <div class="modal fade" id="exampleModal9" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls9" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Beauty_of_Nature_III_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Beauty_of_Nature_III_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls9" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls9" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Beauty of Nature III, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 10 -->
  <div class="modal fade" id="exampleModal10" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls10" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Blue_Red_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Blue_red_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls10" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls10" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
         Blue Red, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 11 -->
  <div class="modal fade" id="exampleModal11" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls11" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_Water_Lilies_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_Water_Lilies_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls11" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls11" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Water Lilies, Acryl on Canvas, 160 x 120 cm (2024)
        </div>
      </div>
    </div>
  </div>  
    
  <!-- Modal 12 -->
  <div class="modal fade" id="exampleModal12" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls12" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_Water_Lilies_II_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_Water_Lilies_II_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls12" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls12" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Water Lilies II, Acryl on Canvas, 80 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 13 -->
  <div class="modal fade" id="exampleModal13" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls13" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Redmiracle.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Redmiracle_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Redmiracle_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Redmiracle_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Redmiracle_4.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls13" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls13" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Red Miracle, Acryl on Canvas, 120 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 14 -->
  <div class="modal fade" id="exampleModal14" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls14" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Green_Sea.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Green_Sea_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Green_Sea_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Green_Sea_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls14" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls14" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Green Sea, Acryl on Canvas, 80 x 80 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 15 -->
  <div class="modal fade" id="exampleModal15" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls15" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pinksprinkles.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pinksprinkles_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pinksprinkles_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pinksprinkles_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pinksprinkles_4.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls15" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls15" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Sprinkles, Acryl on Canvas, 80 x 80 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
</template>

<style scoped>
  #jumbo {
  background-image: url('../assets/Beauty_of_Nature_quadrat.jpg');
  background-position: center;
  background-size: cover;
  height: 50vh;
} 
</style>
