<template>
  <div id="jumbo" class="container-fluid">
  </div>
  
 
  <div id="art" class="container">
    <h1>Powerful Series</h1> 
    
     <!-- 1 of 4 -->   
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Fall_Beauty_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal16" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Fall Beauty (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Hope_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal17" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Hope (2024)</p>
      </div>
    </div>  
    
        <!-- Modal 16 -->
  <div class="modal fade" id="exampleModal16" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls16" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Fall_Beauty.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Fall_Beauty_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Fall_Beauty_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Fall_Beauty_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls16" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls16" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Fall Beauty, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
          <!-- Modal 17 -->
  <div class="modal fade" id="exampleModal17" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls17" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Hope.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Hope_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Hope_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Hope_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Hope_4.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Hope_5.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Hope_6.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Hope_7.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Hope_8.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls17" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls17" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Hope, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
    <!-- 2 of 4 -->
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_flower_2_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal18" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Flower 2 (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Pure_Happiness_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal19" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pure Happiness (2024)</p>
      </div>
    </div>  
    
        <!-- Modal 18 -->
  <div class="modal fade" id="exampleModal18" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls18" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_flower_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_flower_2_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_flower_2_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_flower_2_4.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_flower_2_5.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_flower_2_6.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls18" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls18" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Flower 2, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
          <!-- Modal 17 -->
  <div class="modal fade" id="exampleModal19" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls19" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pure_Happiness.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pure_Happiness_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pure_Happiness_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pure_Happiness_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pure_Happiness_4.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls19" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls19" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pure Happiness, Acryl on Canvas, 120 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  
      <!-- 2 of 4 -->
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Unleashed_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal20" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Unleashed (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Paradisebird_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal21" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Paradise Bird (2024)</p>
      </div>
    </div>  
    
        <!-- Modal 18 -->
  <div class="modal fade" id="exampleModal20" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls20" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Unleashed.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Unleashed_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Unleashed_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Unleashed_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Unleashed_4.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Unleashed_5.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls20" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls20" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Unleashed, Acryl on Canvas, 200 x 150 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
          <!-- Modal 17 -->
  <div class="modal fade" id="exampleModal21" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls21" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Paradisebird.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Paradisebird_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Paradisebird_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Paradisebird_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls21" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls21" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Paradise Bird, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
    
    <!-- OLD -->
    
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/King_fisher_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal12" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Kingfisher (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_greenplosion.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal13" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Greenplosion (2024)</p>
      </div>
    </div>   
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Energy_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal8" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Energy (2023)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Wonderland_1_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal22" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Wonderland 1 (2024)</p>
      </div>
    </div>   
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Flowerexplosion_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal5" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Flower Explosion (2022)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Fireworks_quadratisch.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal1" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Fireworks (2021)</p>
      </div>
    </div>
    
     <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Energy_II_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal9" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Energy II (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Greenplosion_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal10" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Greenplosion (2024)</p>
      </div>
    </div>          


    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Green_Energy_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal11" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Green Energy (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Vigour_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal2" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Vigour (2023)</p>
      </div>
    </div>
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_flower.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal14" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Flower (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Greenplosion_big_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal15" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Big Greenplosion (2024)</p>
      </div>
    </div>
  </div>
  
      <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Candy_quadratisch.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal4" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Candy (2022)</p>
      </div>
    </div>   

  <!-- Modal 1 -->
  <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <img src="../assets/IMG_9040_1.jpg" class="img-fluid" alt="..." style="max-height: 70vh;">
        </div>
        <div class="modal-footer">
          Fireworks (2021)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 2 -->
  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <img src="../assets/Vigour.jpg" class="img-fluid" alt="..." style="max-height: 70vh;">
        </div>
        <div class="modal-footer">
          Vigour (2021)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 4 -->
  <div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <img src="../assets/Big_one_1.jpg" class="img-fluid" alt="..." style="max-height: 70vh;">
        </div>
        <div class="modal-footer">
          Candy (2022), 200 x 150 cm, Acryl on Canvas
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 5 -->
  <div class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <img src="../assets/Flowerexplosion.png" class="img-fluid" alt="..." style="max-height: 70vh;">
        </div>
        <div class="modal-footer">
          Flower Explosion, Acryl on Paper, 80 x 100 cm (2022)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 8 -->
  <div class="modal fade" id="exampleModal8" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Energy.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Energy_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Energy_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Energy, Acryl on Canvas, 200 x 150 cm (2023)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 9 -->
  <div class="modal fade" id="exampleModal9" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls9" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Energy_II_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Energy_II_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls9" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls9" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Energy II, Acryl on Canvas, 200 x 150 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 10 -->
  <div class="modal fade" id="exampleModal10" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls10" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Greenplosion_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Greenplosion_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls10" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls10" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Greenplosion, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 11 -->
  <div class="modal fade" id="exampleModal11" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls11" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Green_Energy_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Green_Energy_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls11" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls11" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Green Energy, Acryl on Canvas, 120 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 12 -->
  <div class="modal fade" id="exampleModal12" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls12" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/King_fisher_Ansicht.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/King_fisher_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/King_fisher_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls12" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls12" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Kingfisher, Acryl on Canvas, 120 x 160 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 13 -->
  <div class="modal fade" id="exampleModal13" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls13" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_Greenplosion_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_Greenplosion_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls13" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls13" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Greenplosion, Acryl on Canvas, 120 x 120 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
    <!-- Modal 14 -->
  <div class="modal fade" id="exampleModal14" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls14" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_flower_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_flower_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls14" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls14" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Flower, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
           <!-- Modal 17 -->
  <div class="modal fade" id="exampleModal22" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls22" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Wonderland_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Wonderland_1_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Wonderland_1_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Wonderland_1_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
               <div class="carousel-item">
                <img src="../assets/Wonderland_1_4.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Wonderland_1_5.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls22" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls22" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Wonderland 1, Acryl on Canvas, 60 x 60 cm (2024)
        </div>
      </div>
    </div>
  </div>

    <!-- Modal 15 -->
  <div class="modal fade" id="exampleModal15" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls15" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Greenplosion_big.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Big_greenplosion_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Big_greenplosion_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Big_greenplosion_Ansicht3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls15" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls15" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Big Greenplosion, Acryl on Canvas, 120 x 160 cm (2024)
        </div>
      </div>
    </div>
  </div>
    
</template>

<style scoped>
  #jumbo {
  background-image: url('../assets/Energy_quadrat.jpg');
  background-position: center;
  background-size: cover;
  height: 50vh;
} 
</style>
