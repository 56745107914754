<template>
  <div id="jumbo" class="container-fluid">
  </div>
  <div id="art" class="container">
    <h1>Calm Series</h1>
     <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/White_1_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal21" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">White 1 (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/White_2_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal22" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">White 2 (2024)</p>
      </div>
    </div>     

    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/White_Splash_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal23" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">White Splash (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/White_Greige_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal24" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">White Greige (2024)</p>
      </div>
    </div>
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Quiet_ocean_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal1" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Quiet Ocean (2023)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Spring_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal3" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Spring (2023)</p>
      </div>
    </div>     

    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Ocean_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal2" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Ocean (2023)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Blue_Ocean_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal4" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Blue Ocean (2024)</p>
      </div>
    </div>
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Green_White_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal5" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Green White (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_Calm_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal6" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Calm (2024)</p>
      </div>
    </div> 
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_Neon_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal7" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Neon (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_Neon_2q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal8" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Neon 2 (2024)</p>
      </div>
    </div> 
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_Neon_3_neu_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal9" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Neon 3 (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_Neon_4q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal10" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Neon 4 (2024)</p>
      </div>
    </div> 
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_Neon_5q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal11" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Neon 5 (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_calm.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal12" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Calm 2 (2024)</p>
      </div>
    </div> 
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Pink-girl_q.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal20" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Girl (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Pink_neon_dreamland.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal14" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Pink Neon Dreamland (2024)</p>
      </div>
    </div> 
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Beige_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal15" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Calm Beige (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Beige_wonderland.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal16" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Beige Wonderland (2024)</p>
      </div>
    </div> 
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Greige_1_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal17" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Greige (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Greige_dark.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal18" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Greige Dark (2024)</p>
      </div>
    </div> 
    
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4">
        <img src="../assets/Greige_light.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal19" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Greige Light (2024)</p>
      </div>
      <div class="col-12 col-md-4">
        <img src="../assets/Ocean_2_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal13" style="width: 80%; cursor: pointer;">
        <p style="margin-top: 10px;">Ocean 2 (2024)</p>
      </div>
    </div> 
    
  </div>


  <!-- Modal 1 -->
  <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls1" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Quiet_ocean.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Quiet_ocean_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Quiet Ocean, Acryl on Canvas, 120 x 100 cm (2023)
        </div>
      </div>
    </div>
  </div>


  <!-- Modal 2 -->
  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <img src="../assets/Ocean.jpg" class="img-fluid" alt="..." style="max-height: 70vh;">
        </div>
        <div class="modal-footer">
          Ocean, Acryl on Canvas, 120 x 100 cm (2023)
        </div>
      </div>
    </div>
  </div>

  <!-- Modal 3 -->
  <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Spring.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Spring_room.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Spring, Acryl on Canvas, 100 x 80 cm (2023)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 4 -->
  <div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls4" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Blue_Ocean_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Blue_Ocean_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls4" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls4" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Blue Ocean, Acryl on Canvas, 100 x 80 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 5 -->
  <div class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls5" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Green_White_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Green_White_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls5" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls5" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Green White, Acryl on Canvas, 100 x 80 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
<!-- Modal 6 -->
  <div class="modal fade" id="exampleModal6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls6" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_Calm_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_Calm_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls6" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls6" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Calm, Acryl on Canvas, 60 x 60 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
<!-- Modal 7 -->
  <div class="modal fade" id="exampleModal7" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls7" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_Neon_V.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_Neon_V2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls7" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls7" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Neon, Acryl on Canvas, 60 x 60 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- Modal 8 -->
  <div class="modal fade" id="exampleModal8" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls8" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_neon_2_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_neon_2_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_neon_2_Ansicht3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls8" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls8" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Neon 2, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 9 -->
  <div class="modal fade" id="exampleModal9" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls9" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_Neon_3_neu_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_Neon_3_neu_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls9" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls9" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Neon 3, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 10 -->
  <div class="modal fade" id="exampleModal10" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls10" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_Neon_4_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_Neon_4_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls10" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls10" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Neon 4, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 11 -->
  <div class="modal fade" id="exampleModal11" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls11" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_neon_5_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_neon_5_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_neon_5_Ansicht3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls11" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls11" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Neon 5, Acryl on Canvas, 80 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 12 -->
  <div class="modal fade" id="exampleModal12" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls12" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_Calm_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_Calm_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls12" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls12" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Calm 2, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 13 -->
  <div class="modal fade" id="exampleModal13" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls13" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Ocean_2_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Ocean_2_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Ocean_2_Ansicht3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls13" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls13" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Ocean 2, Acryl on Canvas, 120 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 14 -->
  <div class="modal fade" id="exampleModal14" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls14" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink_Neon_dream_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink_Neon_dream_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls14" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls14" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Neon Dreamland, Acryl on Canvas, 80 x 80 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 15 -->
  <div class="modal fade" id="exampleModal15" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls15" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Calm_beige_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Calm_beige_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Calm_beige_Ansicht3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls15" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls15" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Calm Beige, Acryl on Canvas, 80 x 80 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 16 -->
  <div class="modal fade" id="exampleModal16" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls16" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Beige_wonderland_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Beige_wonderland_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls16" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls16" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Beige Wonderland, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 17 -->
  <div class="modal fade" id="exampleModal17" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls17" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Greige_1_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Greige_1_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Greige_1_Ansicht3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls17" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls17" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Greige, Acryl on Canvas, 120 x 120 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 18 -->
  <div class="modal fade" id="exampleModal18" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls18" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Greige_dark_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Greige_dark_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls18" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls18" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Greige Dark, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 19 -->
  <div class="modal fade" id="exampleModal19" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls19" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Greige_light_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Greige_light_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Greige_light_Ansicht3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls19" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls19" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Greige Light, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal 20 -->
  <div class="modal fade" id="exampleModal20" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls20" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/Pink-girl.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink-girl_Ansicht1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/Pink-girl_Ansicht2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls20" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls20" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          Pink Girl, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
    <!-- Modal 21 -->
  <div class="modal fade" id="exampleModal21" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls21" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/White_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_1_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_1_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_1_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls21" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls21" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          White 1, Acryl on Canvas, 40 x 120 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
    <!-- Modal 22 -->
  <div class="modal fade" id="exampleModal22" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls22" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/White_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_2_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_2_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_2_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls22" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls22" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          White 2, Acryl on Canvas, 40 x 120 cm (2024)
        </div>
      </div>
    </div>
  </div>

    <!-- Modal 23 -->
  <div class="modal fade" id="exampleModal23" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls23" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/White_Splash.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_Splash_1.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_Splash_2.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_Splash_3.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_Splash_4.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_Splash_5.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_Splash_6.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
              <div class="carousel-item">
                <img src="../assets/White_Splash_7.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls23" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls23" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          White Splash, Acryl on Canvas, 100 x 100 cm (2024)
        </div>
      </div>
    </div>
  </div>
  
    <!-- Modal 24 -->
  <div class="modal fade" id="exampleModal24" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div id="carouselExampleControls24" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="../assets/White_Greige.jpg" class="img-fluid" style="max-height: 70vh;" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls24" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls24" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          White Greige, Acryl on Canvas, 120 x 120 cm (2024)
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  #jumbo {
  background-image: url('../assets/Blue_Ocean_quadrat.jpg');
  background-position: 0% 50%;
  background-size: cover;
  height: 50vh;
} 
</style>
